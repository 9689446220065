.Container{
    background-color: #aacdc68f;;
    max-width: 50%;
    height: 500px;
    border-radius: 20px;
}
.Container button{
    margin-top: 4px;
}
.box label{
   color: blueviolet;
   
}
.box input{
    padding: 5px;
    margin: auto;
}
.box button{
    margin-top: 20px;
}


.form{
    background-color: rgb(139, 139, 236);
    border-radius: 20px;
    
}
.result{
    display: block;
    margin-top: 52px;
}
td{
    color: rgb(225, 11, 232);
}
