.App{
    justify-content: space-between;
    text-align: center;


}
.login{
    width: 350px;
    display: flex;
    margin: 20% 10% !important;

}

